<template>
    <div>
        <Header :openMenu="openMenu" 
        :flgHeadFooter="flgHeadFooter" 
        :showHeadMobile="showHeadMobile"/>

        <section class="wrap">
            <div class="space-holder">
                <div class="sticky">
                    <div class="horizontal" :class="[scrollTrans ? 'transi' : '', turnOfTrans ? 'turnOf' : '']">
                        <section role="feed" class="cards">
                            <div class="sample-card section_1">
                                <div class="bgVideo">
                                    <video autoplay data-autoplay muted loop id="agoranVideo">
                                        <source src="../assets/ironsail.mp4" type="video/mp4">
                                    </video>
                                </div>
                                <a class="logo_whydah_2" href="/"><img src="../assets/logo_whydah_2.svg" alt=""></a>
                                <a class="logo_whydah_2_sp" href="/"><img src="../assets/logo_whydah_sp.svg" alt=""></a>
                                <div class="txtBanner">
                                    <h2>sail the<br> open-metaverse</h2>
                                    <!-- <img class="logo_big_whydah" src="../assets/logo_big_whydah.svg" alt=""> -->
                                    <!-- <img class="text_1_pc" src="../assets/text_1.svg" alt="">
                                    <img class="text_1_sp" src="../assets/text_1_sp.svg" alt=""> -->
                                </div>

                            </div>
                            <div class="sample-card section_2">
                                <div class="txt_section_2">
                                    <div class="section_2_left">
                                        <!-- <img class="text_2_pc" src="../assets/text_2.svg" alt="">
                                        <img class="text_2_sp" src="../assets/text_2_sp.svg" alt=""> -->
                                        <h2>The technological<br> foundation fitting a<br> true open-metaverse</h2>
                                        <p>We aim to eliminate all blockchain barriers for any by bringing a<br> complete set of technologies, tools, and services suitable for developers,<br> investors, and gamers alike.</p>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="sample-card section_3" id="sumary">
                                <div class="section_3_menu">
                                    <ul id="menuPC">
                                        <li><a @click="toSumary('why')">VISION & MISSION</a></li>
                                        <li><a @click="toSumary('how')">APPROACH</a></li>
                                        <li><a @click="toSumary('what')">PRODUCTS & SERVICES</a></li>
                                        <li><a @click="toSumary('news')">NEWS</a></li>
                                    </ul>
                                    <ul id="menuSP">
                                        <li><a href="#why" v-smooth-scroll="{ duration: 1000, offset: -70 }">VISION & MISSION</a></li>
                                        <li><a href="#how" v-smooth-scroll="{ duration: 1000, offset: -70 }">APPROACH</a></li>
                                        <li><a href="#what" v-smooth-scroll="{ duration: 1000, offset: -70 }">PRODUCTS & SERVICES</a></li>
                                        <li><a>NEWS</a></li>
                                    </ul>
                                </div>
                            </div> -->
                            <div class="sample-card section_4" id="why">
                                <!-- <div class="title_page" :class="fixedTitle ? 'fixedTitle' : ''">
                                    <h2>VISION & MISSION</h2>
                                </div> -->
                                <div class="box_section_4">
                                    <div class="content_section_4">
                                        <div class="width50">
                                            <h2 class="title-new">VISION & <br>MISSION</h2>
                                            <img class="imgPic_3 pic_3_1_sp" src="../assets/pic_3_1_sp.png" alt="">

                                            <p>We believe that blockchain will become a pillar of the future human society. Since 2017, we have been a collective group of dedicated infrastructure builders, notably with the KardiaChain blockchain. <br><br>
                                            Among our various approaches to achieve critical mass adoption for blockchain, GameFi and MetaFi stand out as efficient gateways to onboard new users. <br><br>
                                            In 2021, we founded Whydah, named after the most infamous pirate ship in the Age of Discovery. <br><br>
                                            We have a strong belief that GameFi will be the basis of a metaverse due to the interactive nature of gamification. The profound successes with My DeFi Pet and Thetan Arena are the cornerstone for our crew to stay committed to the mission of developing the blockchain ecosystem through gaming.</p>
                                        </div>
                                        <div class="width50 padding0">
                                            <img class="imgPic_3 pic_3_1_pc" src="../assets/pic_3_1.png" alt="">

                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="sample-card section_5" id="how">
                                <div class="box_section_5">
                                    <div class="content_section_5">
                                        <div class="item">
                                            <h3 class="title-new">APPROACH</h3>
                                            <h2>Respect the Game</h2>
                                            <p>The developer crew is the true decision-maker in the game design, roadmap, and community empowerment method.</p>
                                        </div>
                                        <div class="item">
                                            <h3 class="title-new"></h3>
                                            <h2>Sustainable community</h2>
                                            <p>Users-centric game mechanics or user-related activities to ensure longevity for a collective community of investors, players, content creators, and contributors. </p>
                                        </div>
                                        <div class="item">
                                            <h3></h3>
                                            <h2>Investors value</h2>
                                            <p>We aim to increase the value of our investors and traditional businesses by offering them to be the pioneer and contributor in creating the true open-metaverse.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sample-card section_7" id="what">
                                <div class="box_section_6">
                                    <div class="content_section_6">
                                        <div class="row_1">
                                            <div class="column_1">
                                                <h3>PRODUCTS & SERVICES</h3>
                                                <h2 class="mobile">Whydah provides the solutions and blockchain platform powered by KardiaChain for Vietnamese game developers;  allows the business model conversion and blockchain integration into their products with international standards.</h2>
                                                <img class="group_logo" src="../assets/Group_logo_sp.svg" alt="">
                                                <h2 class="desktop">Whydah provides the solutions and blockchain platform powered by KardiaChain for Vietnamese game developers;  allows the business model conversion and blockchain integration into their products with international standards.</h2>
                                            </div>
                                            <div class="column_2">
                                                <div class="group_list_logo">
                                                    <img src="../assets/group_logo.svg" alt="">
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="row_1">
                                            <div class="column_1 ed-col1">
                                                <p @click="toSumary('ironsail')">FIRST WIND / SEE OUR FIRST MOVE</p>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="sample-card section_6" id="what">
                                <div class="box_section_6">
                                    <div class="content_section_6">
                                        <div class="row_1">
                                            <div class="column_1">
                                                <h3>PRODUCTS & SERVICES</h3>
                                            </div>
                                        </div>
                                        <div class="row_2">
                                            <div class="item">
                                                <h3>iron sail</h3>
                                                <img src="../assets/prd_1.png" alt="">
                                                <p>Iron Sail (logo Iron Sail) Iron Sail is a Web3 game hub that serves as the gateway to the open metaverse through the journey into seven promising projects from top Vietnamese game developers. <br><br> <a href="https://ironsail.io/" target="_blank">www.ironsail.io</a></p>
                                            </div>
                                            <div class="item">
                                                <h3>weepaz</h3>
                                                <img src="../assets/Weepaz.gif" alt="">
                                                <p>The pass to the Metaverse The DID management application, to increase engagement between employers and employees, through a rich set of utilities and gamification incentives.</p>
                                                <ul class="downloadApp">
                                                    <li><a href="https://apps.apple.com/be/app/weepaz/id1637617741" target="_blank"><img src="../assets/Appstore.png" alt=""></a></li>
                                                    <li><a href="https://play.google.com/store/apps/details?id=com.whydah.weepaz" target="_blank"><img src="../assets/Google.png" alt=""></a></li>
                                                </ul>
                                            </div>
                                            <div class="item">
                                                <h3>The Agoran NFT marketplace</h3>
                                                <img src="../assets/prd_3.png" alt="">
                                                <p>An exclusive NFT platform for the blockchain game community. Agoran allows users to quickly make in-game NFT transaction with the aim of expanding the community. Agoran’s NFT mostly focus on the blockchain gaming segment thanks to innovative technologies. <br><br> <a target="_blank" href="https://www.agoran.io/">www.agoran.io</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sample-card section_6" id="what">
                                <div class="box_section_6 padding0">
                                    <div class="content_section_6">
                                        <div class="row_1">
                                            <div class="column_1">
                                                <h3>PRODUCTS & SERVICES</h3>
                                            </div>
                                        </div>
                                        <div class="row_2">
                                            <div class="item">
                                                <h3>Raramuri - Sport in Metaverse</h3>
                                                <img src="../assets/prd_4.png" alt="">
                                                <p>The digital sports platform connects event organizers to millions of runners. <br><br>
                                                <strong>40,000+</strong> Downloads <span><strong>3500+</strong> DAU</span><br>
                                                
                                                <strong>150+</strong> Total events organized<br><br>
                                                <a href="https://raramuri.io/" target="_blank">www.raramuri.io</a>
                                                </p>
                                                <ul class="downloadApp">
                                                    <li><a href="https://apps.apple.com/us/app/raramuri/id1612238544" target="_blank"><img src="../assets/Appstore.png" alt=""></a></li>
                                                    <li><a href="https://play.google.com/store/apps/details?id=com.kardiachain.raramuri" target="_blank"><img src="../assets/Google.png" alt=""></a></li>
                                                </ul>
                                            </div>
                                            <div class="item">
                                                <h3>ortho</h3>
                                                <img src="../assets/Ortho.gif" alt="">
                                                <p>A digital fashion house A new open metaverse dedicated to fostering innovation and authenticity in the world of digital fashion.<br><br>
                                                Partnered with LE THANH HOA to introduce the 1st Metaverse Runway in Vietnam - Issued 200 unique NFTs - Top trending space on Spatial<br><br>
                                                <a href="https://ortho.fashion/" target="_blank">www.ortho.fashion</a>
                                                </p>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="sample-card section_8" id="ironsail">
                                <div class="box_section_6">
                                    <div class="content_section_6">                    
                                        <div class="row_1">
                                            <div class="item">
                                                <h3>PRODUCTS & SERVICES</h3>
                                                <p>Iron Sail is a GameFi Hub project for a collective group of people who have trust in the future of the blockchain industry and invest in potential projects. Together, investors will access the Iron Sail hub as collective groups and receive benefits from the best seven promising GameFi projects. </p>
                                                <a target="_blank" href="https://ironsail.io/">Visit site </a>
                                            </div>
                                            <div class="item">
                                                <h3></h3>
                                                <img class="logo_ironsail" src="../assets/img_ironsail.png" alt="">
                                                <img class="bg_ironrail" src="../assets/bg_ironrail.png" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </section>
                    </div>
                </div>
                <div id="divScroll" class="divScroll" v-on:scroll="scrollFuntion" :class="flgDivScroll ? 'show' : ''">
                    <div id="scrollBar" class="scrollBar"></div>
                </div>
                <div class="linneScroll" :class="flgDivScroll ? 'show' : ''"></div>
            </div>
        </section>

        <div class="menu_page" :class="openMenuPc ? 'open' : ''">
            <div class="section_3_menu">
                <ul id="menuPC">
                    <li><a @click="toSumary('why')">VISION & MISSION</a></li>
                    <li><a @click="toSumary('how')">APPROACH</a></li>
                    <li><a @click="toSumary('what')">PRODUCTS & SERVICES</a></li>
                </ul>
                <!-- <ul id="menuSP">
                    <li><a href="#why" v-smooth-scroll="{ duration: 1000, offset: -70 }">VISION & MISSION</a></li>
                    <li><a href="#how" v-smooth-scroll="{ duration: 1000, offset: -70 }">APPROACH</a></li>
                    <li><a href="#what" v-smooth-scroll="{ duration: 1000, offset: -70 }">PRODUCTS & SERVICES</a></li>
                    <li><a>NEWS</a></li>
                </ul> -->
            </div>
        </div>

        <div class="crewTab" :class="flgOpenCrew ? 'open' : ''">
            <div class="content_crew">
                <button class="btnClose" @click="closeCrew">
                    <img src="../assets/close.png" alt="">
                    </button>
                <div class="crew_left">
                    <div class="bod_infor"
                        :class="flgBod1 ? 'active' : ''"
                        @click="openInfoBoss(1)">
                        <h2><span>Tri Pham</span> Chief Executive  Officer</h2>
                        <div class="imgBoss">
                            <img src="../assets/TriPham_2.png" alt="">
                        </div>
                    </div>
                    <div class="bod_infor"
                        :class="flgBod2 ? 'active' : ''"
                        @click="openInfoBoss(2)">
                        <h2><span>Nam Do</span> Chief Technology  Officer</h2>
                        <div class="imgBoss">
                            <img src="../assets/NamDo_2.png" alt="">
                        </div>
                    </div>
                    <div class="bod_infor"
                        :class="flgBod3 ? 'active' : ''"
                        @click="openInfoBoss(3)">
                        <h2><span>Xno Bui</span> Chief Metaverse Officer</h2>
                        <div class="imgBoss">
                            <img src="../assets/TuyetXn_2.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="crew_right">
                    <!-- <h2 class="title_crew">CREW</h2> -->
                    <div class="infoBOD" :class="flgBod1 ? 'active' : ''">
                        <h2>Tri Pham</h2>
                        <p>Tri has 10 years of entrepreneurial experience in the UK. He received a Master of Science degree at UEA London.<br><br>
                        Tri founded KardiaChain in 2018 as an initiative to fix the fragmented blockchain ecosystem. KardiaChain invented the non-invasive cross-chain technology called Dual-node and became the first interoperable blockchain in Southeast Asia.<br><br>
                        In early 2021, Tri launched the GameFi project My DeFi Pet, one of the most remarkable projects on BSC with over 200,000 daily users. Later, in October 2021, he founded Whydah, an entity focusing more on wide-spreading blockchain applications via blockchain gaming and gamification. He started the $25M program called Iron Sail as the first move of Whydah.</p>
                    </div>
                    <div class="infoBOD" :class="flgBod2 ? 'active' : ''">
                        <h2>Nam Do</h2>
                        <p>Graduated from UC Berkeley in Electrical Engineering and Computer Sciences.
                        Started to program in his teenage years and won first and second prizes in the Vietnam National Olympiad in Informatics in high school.<br>
                        Mr Nam has over 10 years of expertise and experience working in Silicon Valley, ranging from Microsoft, Philips Healthcare, Heartflow--a unicorn medical imaging startup, to Google.<br><br>

                        In the entire 7-year at Google, Nam has participated in the transformation of Google’s voice action to become Google Assistant. Most notably, he contributed and led teams to help launch multiple products, including Google Home, Google Hub, and Google Pixel, reaching millions of users.
                        </p>
                    </div>
                    <div class="infoBOD" :class="flgBod3 ? 'active' : ''">
                        <h2>Xno Bui</h2>
                        <p>Ms. Xno Bui has more than 15 years of design experience in various industries from high fashion to interiors, brand orientation, and user experience design. She earned her MBA from the University of Glasgow UK and graduated in Jewelry Design from Central Saint Martins Institute (UK).<br><br>
                        Ms. Xno Bui has a great vision and belief in the digital future of society and the community. That is also the driving force that pushed her to enter the world of blockchain technology in 2018. She is the one who built the brands for KardiaChain and Whydah as well as responsible for the image and experience for a series of the core products. From logo sketches to blockchain game platforms like Iron Sail, her works have been known by millions of users with hundreds of millions of dollars worth.</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="bumper">
            <div class="title_page mt0">
                <h2>PRODUCTS & SERVICES</h2>
            </div>
            <div class="box_section_6">
                <div class="content_section_6">                    
                    <div class="row_1">
                        <div class="item">
                            <p>Iron Sail is a GameFi Hub project for a collective group of people who have trust in the future of the blockchain industry and invest in potential projects. Together, investors will access the Iron Sail hub as collective groups and receive benefits from the best seven promising GameFi projects. </p>
                        </div>
                        <div class="item">
                            <img class="logo_ironsail" src="../assets/img_ironsail.png" alt="">
                            <img class="bg_ironrail" src="../assets/bg_ironrail.png" alt="">
                        </div>
                    </div>
                    <div class="btn_visit">
                        <a target="_blank" href="https://ironsail.io/">Visit site <img src="../assets/line_top.png" alt=""> </a>
                    </div>
                </div>
            </div>
        </div> -->
        <Footer 
            :openCrew="openCrew" 
            :flgHeadFooter="flgHeadFooter"
            :showFootMobile="showFootMobile"
        /> 
    </div>
</template>

<script>
    import Footer from "@/components/Footer.vue";
    import Header from "@/components/Header.vue";
    export default {
        components: {
            Footer,
            Header  
        },
        data(){
            return{
                rect: null,
                getBottom: 0,
                getTop: 9999,
                flgBod1: true,
                flgBod2: false,
                flgBod3: false,
                flgOpenCrew: false,
                scrollTrans: false,
                num: 0,
                flgHeadFooter: false,
                flgDivScroll: false,
                showHeadMobile: false,
                showFootMobile: false,
                flgSumary: false,
                fixedTitle: false,
                turnOfTrans: false,
                openMenuPc: false,
            }
        },
        methods:{
            openInfoBoss(x){
                if(x == 1){
                    this.flgBod1 = true;
                    this.flgBod2 = false;
                    this.flgBod3 = false;
                }
                else if(x == 2){
                    this.flgBod1 = false;
                    this.flgBod2 = true;
                    this.flgBod3 = false;
                }
                else if(x == 3){
                    this.flgBod1 = false;
                    this.flgBod2 = false;
                    this.flgBod3 = true;
                }
            },
            openCrew(){
                this.flgOpenCrew = !this.flgOpenCrew;
                var el = document.body;
                if(this.flgOpenCrew == true){
                    el.classList.add('overload');
                }
                else{
                    el.classList.remove('overload');
                }

            },
            closeCrew(){
                var el = document.body;
                this.flgOpenCrew = false;
                el.classList.remove('overload');
            },
            calcDynamicHeight(){
                const vw = window.innerWidth;
                const vh = window.innerHeight;
                if(window.innerWidth > 993){
                    const objectWidth = document.querySelector('.horizontal').scrollWidth;
                    return objectWidth - vw + vh; 
                }
            },
            openMenu(){
                this.openMenuPc = !this.openMenuPc;
                this.flgOpenCrew = false;
                var el = document.body;
                el.classList.remove('overload');
            },
            toSumary(section){
                const horizontal = document.querySelector('.horizontal');
                var sticky = document.querySelector('.sample-card');
                if(window.innerWidth > 993){
                    if(section == 'why'){
                        this.scrollTrans = true;
                        horizontal.style.transform = `translateX(-${sticky.offsetWidth * 2}px)`;
                    }
                    if(section == 'how'){
                        this.scrollTrans = true;
                        horizontal.style.transform = `translateX(-${sticky.offsetWidth * 3}px)`;
                    }
                    if(section == 'what'){
                        this.scrollTrans = true;
                        horizontal.style.transform = `translateX(-${sticky.offsetWidth * 4}px)`;
                    }
                    if(section == 'ironsail'){
                        this.scrollTrans = true;
                        horizontal.style.transform = `translateX(-${sticky.offsetWidth * 6}px)`;
                    }
                    this.scrollTrans = true;
                    this.openMenuPc = false;
                }
                else{
                    if(section == 'sumary'){
                        const sumary = document.querySelector("#sumary").offsetTop;
                        this.$smoothScroll({
                            scrollTo: sumary,
                            duration: 1000,
                            offset: -70,
                        })
                    }
                    if(section == 'why'){
                        const why = document.querySelector("#why").offsetTop;
                        window.scrollTo({
                            top: why - 70,
                            left: 0,
                            behavior: 'smooth'
                        });
                    }
                    if(section == 'how'){
                        const how = document.querySelector("#how").offsetTop;
                        window.scrollTo({
                            top: how - 70,
                            left: 0,
                            behavior: 'smooth'
                        });
                    }
                    if(section == 'what'){
                        const what = document.querySelector("#what").offsetTop;
                        window.scrollTo({
                            top: what - 70,
                            left: 0,
                            behavior: 'smooth'
                        });
                    }
                    if(section == 'ironsail'){
                        const ironsail = document.querySelector("#ironsail").offsetTop;
                        window.scrollTo({
                            top: ironsail - 70,
                            left: 0,
                            behavior: 'smooth'
                        });
                    }
                }
                
            },
            scrollFuntion(){
                const scrollDiv = document.querySelector('.divScroll').scrollLeft;
                const horizontal = document.querySelector('.horizontal');
                // const spaceHolder = document.querySelector('.space-holder').offsetHeight;
                const spaceHolder = document.querySelector('.sample-card').offsetWidth * 6.7;
                document.querySelector('.scrollBar').style.width = spaceHolder + 'px';
                horizontal.style.transform = `translateX(-${scrollDiv}px)`;
            },
            bindEvents(){
                if(window.innerWidth > 993){
                    const spaceHolder = document.querySelector('.space-holder');
                    const horizontal = document.querySelector('.horizontal');
                    const sampleCard = document.querySelector('.sample-card').offsetWidth;
                    spaceHolder.style.height = this.calcDynamicHeight(horizontal.offsetWidth) + 'px';
                    window.addEventListener('scroll', () => {
                        this.scrollTrans = false;
                        const sticky = document.querySelector('.sticky');
                        horizontal.style.transform = `translateX(-${sticky.offsetTop}px)`;
                        document.querySelector('.divScroll').scrollLeft = sticky.offsetTop;
                        if(sticky.offsetTop > (sampleCard*7)){
                            this.turnOfTrans = true;
                        }
                        else{
                            this.turnOfTrans = false;
                        }
                        // fixed title page
                        if(sticky.offsetTop > (sampleCard*3)){
                            this.fixedTitle = true;
                        }
                        else{
                            this.fixedTitle = false;
                        }

                        // show header + footer 
                        if(sticky.offsetTop > (sampleCard/2)){
                            this.flgHeadFooter = true;
                            this.flgDivScroll = true;
                        }
                        else{
                            this.flgHeadFooter = false;
                            this.flgDivScroll = false;

                        }

                        //Scroll 2 div
                        //  document.querySelector('.divScroll').scrollLeft($(this).scrollLeft());

                    });
            
                    window.addEventListener('resize', () => {
                        spaceHolder.style.height = this.calcDynamicHeight(horizontal.offsetWidth) + 'px';
                    });
                }
                else{
                    window.addEventListener('scroll', () => {
                        const section1 = document.querySelector('.section_1').offsetHeight;
                        if(window.pageYOffset > 200){
                            this.showFootMobile = true;
                        }
                        else{
                            this.showFootMobile = false;
                        }
                        if(window.pageYOffset > section1){
                            this.showHeadMobile = true;
                        }
                        else{
                            this.showHeadMobile = false;
                        }
                    });
                }
            }
        },
        watch:{
            
        },
        mounted() {
            this.calcDynamicHeight();
            this.bindEvents();
            this.scrollFuntion();
        },
    }
</script>

<style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
    // font-family: 'Space Grotesk', sans-serif;
    *, *::before, *::after {
        box-sizing: inherit;
    }
    @font-face {
        font-family: 'Maxi Round';
        src: url(../assets/fonts/ABCMaxi/ABCMaxiRound/ABCMaxiRound-Regular-Trial.otf);
        font-weight: 400;
    }
    @font-face {
        font-family: 'Maxi Round';
        src: url(../assets/fonts/ABCMaxi/ABCMaxiRound/ABCMaxiRound-Bold-Trial.otf);
        font-weight: 700;
    }
    @font-face {
        font-family: 'Maxi Sharp';
        src: url(../assets/fonts/ABCMaxi/ABCMaxiSharp/ABCMaxiSharp-Regular-Trial.otf);
        font-weight: 400;
    }
    @font-face {
        font-family: 'PP Neue Montreal';
        src: url(../assets/fonts/PPNeueMontreal/PPNeueMontreal-Thin.otf);
        font-weight: 200;
    }
    @font-face {
        font-family: 'PP Neue Montreal';
        src: url(../assets/fonts/PPNeueMontreal/PPNeueMontreal-Book.otf);
        font-weight: normal;
    }
    @font-face {
        font-family: 'PP Neue Montreal';
        src: url(../assets/fonts/PPNeueMontreal/PPNeueMontreal-Bold.otf);
        font-weight: bold;
    }
    @font-face {
        font-family: 'PP Neue Machina';
        src: url(../assets/fonts/PPNeueMachina/PPNeueMachina-Light.otf);
        font-weight: 200;
    }
    @font-face {
        font-family: 'PP Neue Machina';
        src: url(../assets/fonts/PPNeueMachina/PPNeueMachina-Regular.otf);
        font-weight: normal;
    }
    @font-face {
        font-family: 'PP Neue Machina';
        src: url(../assets/fonts/PPNeueMachina/PPNeueMachina-Ultrabold.otf);
        font-weight: bold;
    }
    html {
        box-sizing: border-box;
        font-size: 100%;
    }

    body {
        margin: 0;
        font-family: sans-serif;
    }

    .divScroll{
        display: none;
        position: fixed;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: 40%;
        overflow-x: auto;
        height: 20px;
        z-index: 10;
        .scrollBar{
            // width: 9368px;
            height: 100%;
        }
        &::-webkit-scrollbar {
            width: 12px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent; 
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #C5C5C5; 
            border-radius: 9px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555; 
        }
        @media (min-width: 993px){
            transition: all 1s ease;
            opacity: 0;
            &.show{
                opacity: 1;
                bottom: 80px;
                visibility: visible;
                
            }
        }
        @media (max-width: 992px){
            display: none;
        }
    }
    .linneScroll{
        display: none;
        position: fixed;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: 45%;
        height: 5px;
        background: #EEEEEE;
        border-radius: 5px;
        z-index: 9;
        @media (min-width: 993px){
            transition: all 1s ease;
            opacity: 0;
            &.show{
                opacity: 1;
                bottom: 86px;
                visibility: visible;
                
            }
        }
        @media (max-width: 992px){
            display: none;
        }
    }
    @media (min-width: 993px){
        .wrap {
            position: relative;
            width: 100%;
            min-height: 100vh;
        }

        .space-holder {
            position: relative;
            width: 100%;
        }

        .sticky {
            position: sticky;
            top: 0;
            height: 100vh;
            width: 100%;
            overflow-x: hidden;
        }

        .horizontal {
            position: absolute;
            height: 100%;
            will-change: transform;
            transition: all 1s ease-out;

            &.transi{
                transition: all 1s ease-out;
            }
            &.turnOf{
                transition: all 0.2s ease-out;
            }
        }

        .cards {
            position: relative;
            height: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            @media (max-width: 993px){
                display: block;

            }
        }
    }

    .sample-card {
        position: relative;
        height: calc(100vh - 140px);
        width: 100vw;
        transition: all .5s ease;
        flex-shrink: 0;
        @media (max-width: 1920px) and (min-width: 993px){
            height: calc(100vh - 120px);
            padding-top: 20px;
        }
        @media (max-width: 992px){
            height: auto;
            min-height: auto;
            margin-bottom: 200px;
        }
    }

    .title_page{
        position: absolute;
        left: 1px;
        background: #fff;
        top: 1px;
        bottom: 0;
        width: 72px;
        border-right: 1px solid #000;
        padding-top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.b60{
            bottom: -60px;
        }
        &.fixedTitle{
            // position: fixed;
        }
        @media (max-width: 1920px) and (min-width: 993px){
            margin-top: 20px;
            bottom: -10px;
            
        }
        h2{
            margin: 0;
            font-style: normal;
            font-weight: bold;
            font-size: 44px;
            line-height: 46px;
            text-transform: uppercase;
            // font-family: 'PP Neue Machina';
            font-family: 'Space Grotesk', sans-serif;
            color: #000000;
            transform: rotate(-90deg);
            white-space: nowrap;
            margin-left: 10px;

        }
        @media (max-width: 992px){
            position: static;
            padding-top: 0px;
            width: 100%;
            border-top: 1px solid #000;
            border-bottom: 1px solid #000;
            border-right: 0px solid #000;
            padding-top: 10px;
            padding-left: 0px;
            h2{
                transform: rotate(0deg);
                font-size: 36px;
                line-height: 46px;
                margin: 0;
                font-family: 'Maxi Round';
                font-weight: 400;
            }
        }
    }
    .section_1 {
        background: url(../assets/bg_section_2.png) no-repeat;
        background-size: cover;
        position: relative;
        z-index: 999999;
        height: 100vh;
        padding-top: 0;
        .bgVideo{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: -1;
            overflow: hidden;
            video{
                width: 100%;
                height: 100%;
                transform: scale(1.2);
            }
            @media (max-width: 992px){
                display: none;
            }
        }
        h4 {
            text-align: center;
            margin: 0;
            color: #030303;
            font-size: 20px;
            text-transform: uppercase;
            padding-top: 20px;
            font-weight: normal;
        }
        a{
            text-align: center;
            width: 100%;
            display: block;
            padding-top: 20px;
            padding-bottom: 20px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
            img{
                width: 148px;
                // display: none;
                @media (max-width: 992px){
                    width: 100px;
                }
            }
            &.logo_whydah_2_sp{
                display: none;
            }
            
        }
        @media (max-width: 992px){
            background-position-x: center;
            .logo_whydah_2{
                display: none;
            }
            .logo_whydah_2_sp{
                display: block !important;
                img{
                    display: block !important;
                    margin: auto;
                }
            }
        }
        .txtBanner{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            h2 {
                font-weight: 496;
                font-size: 96px;
                line-height: 114px;
                color: #fff;
                // font-family: 'PP Neue Machina';
                font-family: 'Space Grotesk', sans-serif;
                font-weight: bold;
                text-transform: uppercase;
                // @media (min-width: 993px){
                //     br{
                //         &:nth-child(odd){
                //             display: none;
                //         }
                //     }
                // }
            }
            .logo_big_whydah{
                width: 300px;
                margin-bottom: 80px;
            }
            img{
                width: 80%;
            }
            .text_1_sp{
                display: none;
            }
        }
        @media (max-width: 992px){
            .txtBanner{
                h2{
                    font-size: 54px;
                    line-height: 46px;
                }
                .logo_big_whydah{
                    display: none;
                }
                .text_1_pc{
                    display: none;
                }
                .text_1_sp{
                    display: block;
                    width: 90%;
                    margin: auto;
                }
            }
        }
        @media (max-width: 992px){
            .txtBanner{
                h2{
                    font-size: 48px;
                    line-height: 64px;
                }
                img{
                    width: 80%;
                }
            }
        }
    }
    .section_2 {
        position: relative;
        .txt_section_2{
            display: flex;
            flex-wrap: nowrap;
            width: 100%;
            margin: auto;
            border-top: 1px solid #000;
            padding-left: 10%;
            padding-right: 10%;
            justify-content: center;
            height: calc(100% - 28px);
            align-items: center;
            .section_2_left{
                width: 80%;
                text-align: center;
                img{
                    width: 80%;
                    margin-bottom: 100px;
                }
                .text_2_sp{
                    display: none;
                }
                h2{
                    font-weight: 300;
                    font-size: 64px;
                    line-height: 75px;
                    text-transform: uppercase;
                    margin-bottom: 50px;
                    margin-top: 20px;
                    color: #000000;
                    // font-family: 'PP Neue Machina';
                    font-family: 'Space Grotesk', sans-serif;
                }
                p{
                    font-weight: normal;
                    font-size: 20px;
                    // font-family: 'PP Neue Montreal';
                    font-family: 'Space Grotesk', sans-serif;
                    line-height: 30px;
                    color: #000000;
                    margin-bottom: 20px;
                }
                @media (max-width: 768px){
                    width: 100% !important;
                    p{
                        width: 90%;
                        margin: auto;
                        br{
                            display: none;
                        }
                    }
                }
            }
            .section_2_right{
                width: 40%;
            }
        }
        @media (max-width: 992px){
            margin-bottom: 100px;
            border-bottom: 1px solid #000;
            padding-bottom: 100px;
            .txt_section_2{
                width: 100%;
                padding: 0 32px ;
                flex-wrap: wrap;
                flex-direction: column;
                margin: unset;
                border-top: 0;
                .section_2_left{
                    width: 80%;
                    h2{
                        font-size: 36px;
                        line-height: 56px;
                        margin-bottom: 24px;
                        text-align: left;
                    }
                    p{
                        margin-bottom: 30px;
                        text-align: left;
                        width: 100%;
                        font-size: 16px;
                        line-height: 24px;
                    }
                    .text_2_pc{
                        display: none;
                    }
                    .text_2_sp{
                        display: block;
                        width: 90%;
                        margin: auto;
                        margin-bottom: 50px;
                    }
                    
                }
                .section_2_right{
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    img{
                        width: 25%;
                        @media (max-width: 768px){
                            width: 80%;
                        }
                    }
                }
            }
        }
        @media (max-width: 768px){
            .txt_section_2{
                .section_2_left{
                    h2{
                        // font-size: 40px;
                    }
                }
            }
        }
    }
    .section_3{
        @media (max-width: 1920px) and (min-width: 993px){
            margin-bottom: -20px;
            padding-top: 10px;
        }
        .section_3_menu{
            height: 100%;
            @media (min-width: 993px){
                border-top: 1px solid #000;
            }

            ul{
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                li{
                    a{
                        // border-top: 1px solid #000;
                        height: 50px;
                        padding-left: 40px;
                        line-height: 56px;
                        font-weight: bold;
                        // font-family: 'PP Neue Machina';
                        font-family: 'Space Grotesk', sans-serif;
                        font-size: 36px;
                        color: #000000;
                        text-decoration: none;
                        width: 100%;
                        display: block;
                        cursor: pointer;
                        @media (min-width: 993px){
                            &:hover{
                                height: 100px;
                            }
                        }
                        @media (max-width: 992px){
                            padding-left: 20px;
                        }
                        @media (max-width: 768px){
                            font-size: 30px;
                        }
                    }
                    
                }
            }
            @media (min-width: 993px){
                #menuSP{
                    display: none;
                }
            }
            @media (max-width: 992px){
                #menuPC{
                    display: none;
                }
            }
        }
        @media (max-width: 992px){
            display: none;
        }
    }
    .section_4{
        .box_section_4{
            border-top: 1px solid #000;
            border-bottom: 0;
            height: 100%;
            @media (max-width: 1920px) and (min-width: 993px){
                height: calc(100% + 10px);
                display: flex;
                align-items: center;
                
            }
            .content_section_4{
                display: flex;
                // height: calc(100% - 140px);
                align-items: flex-start;
                width: 90%;
                margin-left: auto;
                .width50{
                    width: 50%;
                    padding: 0 60px;
                    h2{
                        font-size: 40px;
                        line-height: 54px;
                        // font-family: 'PP Neue Machina';
                        font-family: 'Space Grotesk', sans-serif;
                        color: #000000;
                        font-weight: 300;
                        margin-bottom: 40px;
                        @media (min-width: 993px){
                            white-space: nowrap;
                            br{
                                display: none;
                            }
                        }
                    }
                    p{
                        font-weight: normal;
                        font-size: 20px;
                        line-height: 28px;
                        text-align: left;
                        margin: 0;
                        color: #000000;
                        // font-family: 'PP Neue Montreal';
                        font-family: 'Space Grotesk', sans-serif;
                        @media (max-height: 770px) and (min-height: 600px){
                            font-size: 16px;
                            line-height: 22px;
                        }
                    }
                    img{
                        // margin-top: 45px;
                        width: 100%;
                        margin-top: 100px;
                        &.pic_3_1_sp{
                            display: none;
                        }
                    }
                    @media (max-width: 992px){
                        width: 100%;
                        padding: 0;
                        margin: 50px 0 0;
                        img{
                            margin-bottom: 0px;
                            &.pic_3_1_pc{
                                display: none;
                            }
                            &.pic_3_1_sp{
                                display: block;
                                margin-top: 0;
                                position: relative;
                                width: 110%;
                                right: 0;
                            }
                        }
                        p{
                            text-align: left;
                            font-size: 16px;
                            line-height: 24px;
                            margin-top: 40px;
                        }
                    }
                    &.padding0{
                        padding: 0;
                    }
                }
                
            }
        }
        @media (max-width: 992px){
            margin-bottom: 50px;
            padding-bottom: 50px;
            border-bottom: 1px solid #000;
            .box_section_4{
                border: 0;
                .content_section_4{
                    width: 100%;
                    height: auto;
                    padding: 0 32px;
                    display: block;
                    overflow-x: hidden;
                    img.pic_2{
                        width: 60%;
                        float: left;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        shape-outside: inset(calc(50% - 102px) 0 );
                    }
                    .width50{
                        h2{
                            font-size: 36px;
                            line-height: 54px;
                        }
                    }
                    p{
                        width: 100%;
                        padding-right: 0;
                        margin-bottom: 20px;
                    }
                    .imgPic_3{
                        width: 100%;
                        margin-bottom: 100px;
                    }
                }
            }
        }
    }
    .section_5{
        .box_section_5{
            border-top: 1px solid #000;
            border-left: 0;
            border-bottom: 0;
            height: 100%;
            @media (max-width: 1920px) and (min-width: 993px){
                height: calc(100% + 20px);
            }
            .content_section_5{
                --col: 3;
                --space: 50px;
                display: flex;
                flex-wrap: nowrap;
                padding: 0 200px;
                height: calc(100% - 70px);
                align-items: center;
                margin: auto;
                .item{
                    width: calc(100% / var(--col));
                    padding-right: var(--space);
                    min-height: 200px;
                    img{
                        margin-bottom: 20px;
                    }
                    h3{
                        font-size: 40px;
                        height: 54px;
                        line-height: 54px;
                        // font-family: "PP Neue Machina";
                        font-family: 'Space Grotesk', sans-serif;
                        color: #000000;
                        font-weight: 300;
                        margin-bottom: 40px;  
                        @media (max-width: 992px){
                            font-size: 36px;
                            text-align: left;
                            height: auto;
                        }      
                    }
                    h2{
                        font-weight: bold;
                        font-size: 24px;
                        line-height: 32px;
                        margin-bottom: 25px;
                        color: #000000;
                        // font-family: 'PP Neue Machina';
                        font-family: 'Space Grotesk', sans-serif;
                        text-transform: uppercase;
                    }
                    p{
                        font-weight: normal;
                        // font-family: 'PP Neue Montreal';
                        font-family: 'Space Grotesk', sans-serif;
                        font-size: 20px;
                        line-height: 28px;
                        margin: 0;
                        color: #000000;
                        height: 140px;
                        @media (max-height: 770px) and (min-height: 600px){
                            font-size: 16px;
                            line-height: 22px;
                        }
                    }
                }
                @media (max-width: 1450px) and (min-width: 1201px){
                    padding: 0 50px 0 150px;
                }
                @media (max-width: 1200px) and (min-width: 993px){
                    padding: 0 0px 0 80px;
                    .item{
                        min-height: auto;
                        h2{
                            font-size: 30px;
                            line-height: 36px;
                        }
                    }
                }
            }
        }
        @media (max-width: 992px){
            margin-bottom: 0px;
            .box_section_5{
                border: 0;
                border-bottom: 1px solid #000;
                margin-bottom: 30px;
                .content_section_5{
                    --col: 1;
                    padding: 50px 32px 50px;
                    flex-wrap: wrap;
                    margin-right: 0;
                    .item{
                        padding-right: 0;
                        margin-bottom: 40px;
                        text-align: center;
                        min-height: auto;
                        &:first-child{
                            h3{
                                margin-bottom: 40px;
                            }
                        }
                        h3{
                            margin-bottom: 0;
                        }
                        img{
                            width: 100%;
                            margin-bottom: 12px;
                        }
                        h2{
                            font-size: 24px;
                            line-height: 26px;
                            margin-bottom: 12px;
                            text-align: left;
                        }
                        p{
                            font-size: 16px;
                            line-height: 24px;
                            height: auto;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
    .section_6{
        .box_section_6{
            border-top: 1px solid #000;
            border-left: 0;
            border-bottom: 0;
            height: 100%;
            .content_section_6{
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                .row_1{
                    display: flex;
                    flex-wrap: nowrap;
                    padding: 0 200px;
                    transition: all;
                    width: 100%;
                    @media (max-height: 770px) and (min-height: 600px){
                        padding: 0 90px;
                    }
                    .column_1{
                        width: 100%;
                        h3{
                            font-size: 40px;
                            height: 54px;
                            line-height: 54px;
                            // font-family: "PP Neue Machina";
                            font-family: 'Space Grotesk', sans-serif;
                            color: #000000;
                            font-weight: 300;
                            margin-bottom: 15px;
                            white-space: nowrap;
                            @media (max-height: 770px) and (min-height: 600px){
                                font-size: 36px;
                                margin-bottom: 0px;
                            }
                            @media (max-width: 992px){
                                font-size: 36px;
                                line-height: 54px;
                                margin-bottom: 24px;
                                height: auto;
                                display: none;
                            }
                        }
                        h2{
                            width: 92%;
                            font-weight: normal;
                            font-size: 20px;
                            transition: all;
                            line-height: 28px;
                            // font-family: 'PP Neue Montreal';
                            font-family: 'Space Grotesk', sans-serif;
                            margin: 0;
                            color: #000000;
                            @media (max-height: 770px) and (min-height: 600px){
                                font-size: 16px;
                                line-height: 22px;
                            }
                        }
                    }
                    .column_2{
                        width: 30%;
                        text-align: right;
                        p{
                            text-align: left;
                            font-weight: normal;
                            font-size: 38px;
                            line-height: 46px;
                            color: #000000;
                            margin: 0;
                            
                        }
                        img{
                            margin-top: -30px;
                            width: 10px;
                        }
                        @media (max-width: 768px){
                            width: 100% !important;
                            img{
                                margin-right: 10px;
                            }
                        }
                    }
                    @media (max-width: 1350px) and (min-width: 993px){
                        padding: 0 100px;
                        .column_1{
                            h2{
                                font-size: 28px;
                                width: 90%;
                            }
                        }
                    }
                }
                .row_2{
                    --colItem: 3;
                    --spacing: 40px;
                    display: flex;
                    flex-wrap: nowrap;
                    padding: 0 200px;
                    @media (max-height: 770px) and (min-height: 600px){
                        padding: 0 90px;
                    }
                    .item{
                        width: calc(100% / var(--colItem));
                        padding-right: var(--spacing);
                        h3{
                            font-size: 24px;
                            line-height: 32px;
                            white-space: nowrap;
                            margin: 0px 0 12px;
                            color: #000000;
                            font-weight: 500;
                            // font-family: 'PP Neue Machina';
                            font-family: 'Space Grotesk', sans-serif;
                            text-transform: uppercase;
                        }
                        .downloadApp{
                            display: flex;
                            gap: 20px;
                            flex-wrap: nowrap;
                            li{
                                a{
                                    img{
                                        width: 100%;
                                    }
                                }
                            }
                        }
                        p{
                            font-weight: 200;
                            // font-family: 'PP Neue Montreal';
                            font-family: 'Space Grotesk', sans-serif;
                            font-size: 20px;
                            line-height: 28px;
                            margin: 0;
                            color: #000000;
                            margin-bottom: 20px;
                            @media (max-height: 770px) and (min-height: 600px){
                                font-size: 14px;
                                line-height: 20px;
                                height: auto;
                                br.hide{
                                    display: none;
                                }
                            }
                            a{
                                color: #000000;
                                text-decoration: underline;
                            }
                            span{
                                margin-left: 20px;
                            }
                        }
                        >img{
                            margin-bottom: 20px;
                            width: 100%;
                            border-radius: 5px;
                        }
                    }
                    @media (max-width: 1350px) and (min-width: 993px){
                        padding: 0 100px;
                    }
                }
            }
            @media (max-width: 992px){
                border: 0;
                padding: 50px 32px 50px;
                .content_section_6{
                    border-bottom: 0;
                    .row_1{
                        padding: 0px;
                        flex-wrap: wrap;
                        .column_1{
                            width: 100%;
                            h2{
                                margin-bottom: 38px;
                                width: 100%;
                                text-align: left;
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }
                        .column_2{
                            width: 30%;
                        }
                    }
                    .row_2{
                        --colItem: 1;
                        padding: 0px;
                        flex-wrap: wrap;
                        // margin-top: 40px;
                        .item{
                            text-align: center;
                            padding: 0;
                            margin-bottom: 50px;
                            h3{
                                margin-top: 0;
                                margin-bottom: 15px;
                                font-size: 20px;
                                line-height: 28px;
                                text-align: left;
                                width: 100%;
                            }
                            p{
                                margin-bottom: 15px;
                                height: auto;
                                font-size: 16px;
                                line-height: 24px;
                                margin-bottom: 20px;
                                text-align: left;
                            }
                        }

                    }  
                }
            }
            @media (max-width: 768px){
                .content_section_6{
                    .row_1{
                        .column_1{
                            h2{
                                margin-bottom: 55px;
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 992px){
            margin-bottom: 0px;
            .box_section_6{
                padding-bottom: 30px;
                margin-top: -50px;
                &.padding0{
                    padding-top: 0;
                }
            }
        }
    }
    .section_7{
        .box_section_6{
            border-top: 1px solid #000;
            border-left: 0;
            border-bottom: 0;
            height: 100%;
            .content_section_6{
                height: 100%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                .row_1{
                    display: flex;
                    flex-wrap: nowrap;
                    padding: 0 200px;
                    align-items: flex-start;
                    transition: all;
                    margin-bottom: 100px;
                    padding-right: 0;
                    .column_1{
                        width: 40%;
                        h3{
                            font-size: 40px;
                            height: 54px;
                            line-height: 54px;
                            // font-family: "PP Neue Machina";
                            font-family: 'Space Grotesk', sans-serif;
                            color: #000000;
                            font-weight: 300;
                            margin-bottom: 40px;
                            white-space: nowrap;
                            @media (max-width: 992px){
                                white-space: unset;
                            }
                        }
                        h2{
                            width: 92%;
                            font-weight: normal;
                            font-size: 20px;
                            transition: all;
                            line-height: 28px;
                            margin: 0;
                            color: #000000;
                            // font-family: "PP Neue Montreal";
                            font-family: 'Space Grotesk', sans-serif;
                            @media (max-height: 770px) and (min-height: 600px){
                                font-size: 16px;
                                line-height: 22px;
                            }
                            &.mobile{
                                display: none;
                            }
                        }
                        img{
                            display: none;
                        }
                    }
                    .column_2{
                        width: 60%;
                        text-align: right;
                        position: relative;
                        p{
                            text-align: left;
                            font-weight: normal;
                            font-size: 38px;
                            line-height: 46px;
                            color: #000000;
                            margin: 0;
                        }
                        .group_list_logo{
                            position: absolute;
                            left: 0;
                            top: -50px;
                            width: 100%;
                            // top: 0;
                        }
                        img{
                            
                            width: 100%;
                        }
                        @media (max-width: 768px){
                            width: 100% !important;
                            img{
                                margin-right: 10px;
                            }
                        }
                    }
                    .ed-col1{
                        display: flex;
                        align-items: center;
                        p{
                            text-align: left;
                            font-weight: bold;
                            font-size: 20px;
                            line-height: 28px;
                            color: #000000;
                            // font-family: 'PP Neue Machina';
                            font-family: 'Space Grotesk', sans-serif;
                            margin: 0;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                        img{
                            margin-right: 40px;
                            display: block !important;
                        }
                        @media (max-width: 768px){
                            width: 100% !important;
                            img{
                                margin-right: 10px;
                            }
                        }
                    }
                    @media (max-width: 1350px) and (min-width: 993px){
                        padding: 0 100px;
                        .column_1{
                            h2{
                                font-size: 28px;
                                width: 90%;
                            }
                        }
                    }
                }
                .row_2{
                    padding: 0 200px;
                    .list_logo_top{
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;
                        margin-bottom: 100px;
                        img{
                            margin-right: 15px;
                        }
                    }
                    .list_logo_bottom{
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;
                        img{
                            margin-right: 50px;
                        }
                    }
                    .list_logo_top_sp{
                        display: none;
                    }
                }
            }
            @media (max-width: 992px){
                border: 0;
                padding: 50px 32px 0px;
                .content_section_6{
                    .row_1{
                        padding: 0px;
                        flex-wrap: wrap;
                        margin-bottom: 0;
                        .column_1{
                            width: 100%;
                            img{
                                margin-bottom: 25px;
                                &.img_sp{
                                    display: none;
                                }
                                &.group_logo{
                                    display: block;
                                    width: 112%;
                                    margin-left: -32px;
                                }
                            }
                            h3{
                                font-size: 36px;
                                text-align: left;
                                height: auto;           
                            }
                            h2{
                                margin-bottom: 25px;
                                width: 100%;
                                text-align: left;
                                font-size: 16px;
                                line-height: 24px;
                                &.mobile{
                                    display: block;
                                }
                                &.desktop{
                                    display: none;
                                }

                            }
                            &.ed-col1{
                                p{
                                    font-size: 16px;
                                    line-height: 24px;
                                }
                            }
                        }
                        .column_2{
                            width: 30%;
                            display: none;
                        }
                    }
                    .row_2{
                        padding: 0 20px;
                        display: flex;
                        justify-content: space-between;
                        .list_logo_top, .list_logo_bottom{
                            flex-wrap: wrap;
                            flex-direction: column;
                            align-items: flex-start;
                            img{
                                margin: 0;
                                margin-bottom: 30px;
                            }
                        }
                        .list_logo_bottom{
                            img{
                                margin-bottom: 50px;
                                margin-top: 17px;
                            }
                        }
                        .list_logo_top{
                            display: none;
                        }
                        .list_logo_top_sp{
                            display: block;
                            img{
                                width: 100%;
                                margin-bottom: 55px;
                            }
                        }
                    }  
                }
            }
            @media (max-width: 767px){
                .content_section_6{
                    .row_1{
                        .column_1{
                            img{
                                &.group_logo{
                                    display: block;
                                    width: 117%;
                                    margin-left: -32px;
                                }
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 992px){
            margin-bottom: 0;
            .title_page{
                display: none;
            }
        }
    }
    .section_8{
        .box_section_6{
            border-top: 1px solid #000;
            border-left: 0;
            height: 100%;
            .content_section_6{
                height: calc(100% - 70px);
                display: flex;
                justify-content: center;
                flex-direction: column;
                position: relative;
                .row_1{
                    --colItem: 3;
                    --spacing: 40px;
                    display: flex;
                    flex-wrap: nowrap;
                    padding: 0 200px;
                    gap: 50px;
                    @media (max-width: 1350px) and (min-width: 993px){
                        padding: 0 100px;
                    }
                    &.mb50{
                        margin-bottom: 100px;
                    }
                    .item{
                        &:nth-child(1){
                            width: 40%;
                        }
                        &:nth-child(2){
                            width: 60%;
                        }
                        
                        // padding-right: var(--spacing);
                        .logo_ironsail{
                            width: 100%;
                        }
                        h3{
                            font-size: 56px;
                            line-height: 54px;
                            // font-family: "PP Neue Machina";
                            font-family: 'Space Grotesk', sans-serif;
                            color: #000000;
                            font-weight: bold;
                            margin-bottom: 40px;
                            white-space: nowrap;
                            @media (max-width: 992px){
                                white-space: unset;
                                display: none;
                            }
                            height: 60px;
                        }
                        p{
                            font-weight: normal;
                            font-size: 20px;
                            line-height: 28px;
                            // font-family: "PP Neue Montreal";
                            font-family: 'Space Grotesk', sans-serif;
                            margin: 0;
                            color: #000000;
                            font-weight: normal;
                            margin-bottom: 40px;
                        }
                        a{
                            font-weight: bold;
                            text-transform: uppercase;
                            font-size: 20px;
                            line-height: 28px;
                            // font-family: "PP Neue Machina";
                            font-family: 'Space Grotesk', sans-serif;
                            margin: 0;
                            color: #000000;
                            text-decoration: underline;
                        }
                        img.bg_ironrail{
                            display: none;
                        }
                    }
                }
                .btn_visit{
                    text-align: center;
                    position: absolute;
                    bottom: 50px;
                    right: 15%;
                    z-index: 999;
                    @media (max-width: 992px){
                        bottom: 80px;
                    }
                    a{
                        font-weight: bold;
                        font-size: 22px;
                        line-height: 28px;
                        color: #000000;
                        text-decoration: none;
                        border: 0;
                        background: transparent;
                        display: flex;
                        align-items: center;
                        img{
                            // width: 20px;
                            margin-left: 10px;
                        }
                    }
                }
                .row_2{
                    padding: 0 200px;
                    @media (max-width: 1350px) and (min-width: 993px){
                        padding: 0 100px;
                    }
                    .title_ironsail{
                        font-size: 22px;
                        line-height: 28px;
                        font-weight: bold;
                        margin-bottom: 50px;
                    }
                    .box_games{
                        display: flex;
                        .item_game{
                            text-align: center;
                            margin-right: 50px;
                            h2{
                                font-size: 22px;
                                line-height: 28px;
                                font-weight: normal;
                                margin-top: 15px;
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 992px){
            height: auto;
            .title_page{
                display: none;
            }
            .box_section_6{
                border: 0;
                .content_section_6{
                    padding: 0px 32px 0px;
                    height: unset;
                    .row_1{
                        --colItem: 1;
                        flex-wrap: wrap;
                        padding: 0;
                        flex-direction: column-reverse;
                        gap: 0;
                        .item{
                            width: 100% !important;
                            padding: 0;
                            img{
                                margin-bottom: 30px;
                                width: 100%;
                                &.logo_ironsail{
                                    display: none;
                                }
                                &.bg_ironrail{
                                    display: block;
                                    position: relative;
                                    left: -32px;
                                    width: 108%;
                                }
                            }
                           
                            p{
                                margin-bottom: 40px;
                                font-size: 20px;
                                line-height: 28px;
                                text-align: left;
                            }
                            .btn_visit{
                                text-align: left;
                            }
                        }
                    }
                    .row_2{
                        padding: 0;
                        .box_games{
                            display: flex;
                            justify-content: space-between;
                            .item_game{
                                margin-right: 0px;
                                
                            }
                        }
                    }
                }
            }
        }
    }
    .bumper{
        height: calc(100vh - 70px);
        position: relative;
        z-index: 1;
        @media (max-width: 1920px) and (min-width: 993px){
            height: calc(100vh - 80px);
            .mt0{
                margin-top: 0;
            }
        }
        .box_section_6{
            border-top: 1px solid #000;
            border-left: 0;
            height: 100%;
            .content_section_6{
                height: calc(100% - 70px);
                display: flex;
                justify-content: center;
                flex-direction: column;
                position: relative;
                .row_1{
                    --colItem: 3;
                    --spacing: 40px;
                    display: flex;
                    flex-wrap: nowrap;
                    padding: 0 200px;
                    gap: 50px;
                    @media (max-width: 1350px) and (min-width: 993px){
                        padding: 0 100px;
                    }
                    &.mb50{
                        margin-bottom: 100px;
                    }
                    .item{
                        &:nth-child(1){
                            width: 40%;
                        }
                        &:nth-child(2){
                            width: 60%;
                        }
                        
                        // padding-right: var(--spacing);
                        .logo_ironsail{
                            width: 100%;
                        }
                        h3{
                            font-weight: bold;
                            font-size: 22px;
                            line-height: 28px;
                            margin: 50px 0;
                            color: #000000;
                        }
                        p{
                            font-weight: normal;
                            font-size: 22px;
                            line-height: 28px;
                            margin: 0;
                            color: #000000;
                            font-weight: 300;
                            margin-bottom: 20px;
                        }
                        img.bg_ironrail{
                            display: none;
                        }
                    }
                }
                .btn_visit{
                    text-align: center;
                    position: absolute;
                    bottom: 50px;
                    right: 15%;
                    z-index: 999;
                    @media (max-width: 992px){
                        bottom: 80px;
                    }
                    a{
                        font-weight: bold;
                        font-size: 22px;
                        line-height: 28px;
                        color: #000000;
                        text-decoration: none;
                        border: 0;
                        background: transparent;
                        display: flex;
                        align-items: center;
                        img{
                            // width: 20px;
                            margin-left: 10px;
                        }
                    }
                }
                .row_2{
                    padding: 0 200px;
                    @media (max-width: 1350px) and (min-width: 993px){
                        padding: 0 100px;
                    }
                    .title_ironsail{
                        font-size: 22px;
                        line-height: 28px;
                        font-weight: bold;
                        margin-bottom: 50px;
                    }
                    .box_games{
                        display: flex;
                        .item_game{
                            text-align: center;
                            margin-right: 50px;
                            h2{
                                font-size: 22px;
                                line-height: 28px;
                                font-weight: normal;
                                margin-top: 15px;
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 992px){
            height: auto;
            .title_page{
                display: none;
            }
            .box_section_6{
                border: 0;
                .content_section_6{
                    padding: 0px 20px 100px;
                    height: unset;
                    .row_1{
                        --colItem: 1;
                        flex-wrap: wrap;
                        padding: 0;
                        flex-direction: column-reverse;
                        gap: 0;
                        .item{
                            width: 100% !important;
                            padding: 0;
                            img{
                                margin-bottom: 30px;
                                width: 100%;
                                &.logo_ironsail{
                                    display: none;
                                }
                                &.bg_ironrail{
                                    display: block;
                                }
                            }
                            p{
                                margin-bottom: 40px;
                                text-align: center;
                                font-size: 16px;
                                line-height: 18px;
                                text-align: center;
                            }
                            .btn_visit{
                                text-align: left;
                            }
                        }
                    }
                    .row_2{
                        padding: 0;
                        .box_games{
                            display: flex;
                            justify-content: space-between;
                            .item_game{
                                margin-right: 0px;
                                
                            }
                        }
                    }
                }
            }
        }
    }
    .crewTab{
        position: fixed;
        left: 0;
        right: 0;
        top: 100%;
        bottom: 70px;
        width: 100%;
        background: #fff;
        transition: all .5s;
        z-index: 999999;
        overflow: hidden;
        visibility: hidden;
        @media (max-width: 1920px) and (min-width: 993px){
            bottom: 50px;
        }
        .content_crew{
            height: 100%;
            position: relative;
            display: flex;
            .btnClose{
                display: none;
            }
            .crew_left{
                background: #000;
                width: calc(100% - 512px);
                padding: 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .bod_infor{
                    display: flex;
                    justify-content: space-between;
                    border-top: 1px solid #fff;
                    height: 46px;
                    cursor: pointer;
                    transition: all .5s ease;
                    @media (max-width: 768px){
                        flex-wrap: wrap;
                    }
                    &:last-child{
                        border-bottom: 1px solid #fff;
                        @media (max-width: 992px){
                            border-bottom: 0;
                        }
                    }
                    &.active{
                        height: 442px;
                        .imgBoss{
                            img{
                                transform: scale(0.8) translateX(50px);
                                opacity: 0;
                                @media (max-width: 992px){
                                    transform: scale(0.85) translate(-15px, -15px) ;
                                }
                            }
                        }
                        @media (max-width: 992px){
                            height: 230px;
                        }
                    }
                    h2{
                        font-weight: normal;
                        font-size: 24px;
                        line-height: 46px;
                        white-space: nowrap;
                        color: #FFFFFF;
                        margin: 0;
                        flex: 1;
                        font-family: 'Space Grotesk', sans-serif;
                        // font-family: "PP Neue Machina";
                        @media (min-width: 993px){
                            display: flex;
                            justify-content: left;
                        }
                        >span{
                            min-width: 184px;
                            display: inline-block;
                            font-weight: bold;
                            text-align: right;
                            padding-right: 24px;
                        }
                    }
                    .imgBoss{
                        flex-shrink: 0;
                        width: 371px;
                        img{
                            height: 100%;
                            transition: all 1s ease;
                            transform: scale(0);
                            opacity: 0;
                            aspect-ratio: 1 / 1.2;
                        }
                        @media (max-width: 992px){
                            width: 30%;
                        }
                    }
                    @media (max-width: 1350px){
                        display: block;
                        h2{
                            width: 100%;
                            white-space: nowrap;
                            text-align: right;
                            position: relative;
                            font-size: 16px;
                            line-height: 46px;
                            span{
                                position: absolute;
                                left: 0px;
                                min-width: auto;
                            }
                        }
                        .imgBoss{
                            height: 80%;
                            width: max-content;
                            // margin-left: auto;
                        }
                    }
                }
            }
            .crew_right{
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                h2.title_crew{
                    font-weight: bold;
                    font-size: 44px;
                    line-height: 46px;
                    color: #000000;
                    transform: rotate(-90deg);
                    width: fit-content;
                    position: absolute;
                    top: 150px;
                    margin-bottom: 0;
                    margin-left: -20px;
                }
                .infoBOD{
                    visibility: hidden;
                    position: absolute;
                    @media (min-width: 993px){
                        h2{
                            padding-left: 73px;
                            // font-family: "PP Neue Machina";
                            font-family: 'Space Grotesk', sans-serif;
                            font-weight: bold;
                        }
                    }
                    p{
                        width: 70%;
                        margin: auto;
                        font-weight: normal;
                        // font-family: 'PP Neue Montreal';
                        font-family: 'Space Grotesk', sans-serif;
                        font-size: 16px;
                        line-height: 28px;
                        color: #000000;
                        @media (max-width: 992px){
                            margin: unset;
                            width: 100%;
                            font-size: 16px;
                            line-height: 24px;
                            padding-bottom: 50px;
                        }
                    }
                    &.active{
                        position: relative;
                        visibility: visible;
                        @media (max-height: 770px) and (min-height: 600px){
                            max-height: 100%;
                            overflow-y: auto;
                        }
                    }
                }
            }
        }
        &.open{
            top: 70px;
            visibility: visible;
        }
        @media (max-width: 992px){
            bottom: 0;
            .content_crew{
                flex-wrap: wrap;
                display: block;
                height: 100vh;
                .btnClose{
                    display: block;
                    position: absolute;
                    right: 32px;
                    top: 20px;
                    background: transparent;
                    border: 0;
                    width: 20px;
                    height: 20px;
                    padding: 0;
                    img{
                        object-fit: cover;
                        object-position: center;
                        width: 100%;
                    }
                }
                .crew_left{
                    width: 100%;
                    height: fit-content;
                    padding: 70px 32px 0;
                    justify-content: flex-start;
                }
                .crew_right{
                    width: 100%;
                    position: relative;
                    height: calc(100vh - 450px);
                    overflow-y: auto;
                    h2.title_crew{
                        margin-left: -20px;
                        top: 60px;
                        display: none;
                    }
                    .infoBOD{
                        overflow-y: auto;
                        // max-height: calc(100vh - 450px);
                        // height: 100%;
                        padding: 0 32px;
                        h2{
                            // font-family: "PP Neue Machina";
                            font-family: 'Space Grotesk', sans-serif;
                            font-weight: 700;
                            font-size: 36px;
                            margin-top: 40px;
                        }
                    }
                    p{
                        margin-top: 40px;
                        margin-left: auto;
                        
                    }
                }
            }
            &.open{
                top: 0;
            }
        }
        @media (max-width: 768px){
            .content_crew{
                .title_crew{
                    display: none;
                }
                
                .crew_right{
                    p{
                        margin-top: 15px;
                        margin-left: 100px;
                        line-height: 27px;
                    }
                }
            }
        }
    }
    @media (max-width: 992px){
        .space-holder{
            height: auto;
        }
    }

    .menu_page{
        position: fixed;
        left: 0;
        top: 82px;
        bottom: 200%;
        right: 0;
        background: #fff;
        transition: all 1.5s;
        z-index: 999999;
        visibility: hidden;
        overflow: hidden;
        &.open{
            bottom: 0;
            visibility: visible;

        }
        .section_3_menu{
            height: 100%;
            ul{
                display: flex;
                flex-direction: column;
                height: 100%;
                align-items: center;
                justify-content: center;
                li{
                    a{
                        padding-left: 40px;
                        line-height: 56px;
                        padding-bottom: 20px;
                        font-weight: bold;
                        // font-family: 'PP Neue Machina';
                        font-family: 'Space Grotesk', sans-serif;
                        font-size: 36px;
                        color: #000000;
                        text-decoration: none;
                        width: 100%;
                        display: block;
                        cursor: pointer;
                        @media (min-width: 993px){
                            &:hover{
                                padding-bottom: 50px;
                            }
                        }
                        @media (max-width: 992px){
                            padding-left: 20px;
                        }
                        @media (max-width: 768px){
                            font-size: 30px;
                        }
                    }
                    
                }
            }
            @media (min-width: 993px){
                #menuSP{
                    display: none;
                }
            }
            @media (max-width: 992px){
                #menuPC{
                    display: none;
                }
            }
        }

    }
</style>
