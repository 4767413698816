<template>
    <div class="logo_page" :class="[flgHeadFooter ? 'show' : '', showHeadMobile ? 'showMobile' : '']">
        <a href="/"><img src="../assets/logo_whydah.svg" alt=""></a>
        <div class="sumary_menu">
            <a id="sumaryPC" @click="openMenuHeader">
                <h2>SUMMARY</h2>
                <span class="dot_black"></span>
                <span class="dot_white"></span>
            </a>
            <!-- <a id="sumarySP" href="#sumary" v-smooth-scroll="{ duration: 1000, offset: -70 }">
                <span class="dot_black"></span>
                <span class="dot_white"></span>
            </a> -->
        </div>
    </div>
</template>

<script>
    export default {
        props: ['openMenu', 'flgHeadFooter', 'showHeadMobile'],
        methods: {
            openMenuHeader(){
                this.openMenu();
            }
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .logo_page{
        text-align: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background: #fff;
        padding: 20px;
        z-index: 999;
        visibility: hidden;
        @media (min-width: 993px){
            transition: all 1s ease;
            opacity: 0;
            top: 0px;
            left: 50px;
            right: 50px;
            &.show{
                opacity: 1;
                top: 0px;
                left: 0;
                right: 0;
                visibility: visible;
            }
        }
        @media (max-width: 992px){
            transition: all 1.5s ease;
            opacity: 0;
            top: 70px;
            &.showMobile{
                opacity: 1;
                visibility: visible;
                top: 0;
                border-bottom: 1px solid #000;
            }
        }
        >a{
            img{
                width: 148px;
                position: relative;
                top: 7px;
                @media (max-width: 992px){
                    width: 100px;
                    top: 0;
                }
            }
            &.logo_whydah_sp{
                display: none;
            }
        }
        .sumary_menu{
            position: absolute;
            right: 65px;
            top: 25px;
            z-index: 9999;
            >a{
                display: flex;
                align-items: center;
                text-decoration: none;
                cursor: pointer;
                h2{
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 20px;
                    text-transform: uppercase;
                    color: #000000;
                    margin: 0;
                    // font-family: 'PP Neue Machina';
                    font-family: 'Space Grotesk', sans-serif;
                    margin-right: 15px;
                }
                span{
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    border: 1px solid #000;
                    transition: all .5s ease;
                    &.dot_black{
                        background: #000;
                    }
                    &.dot_white{
                        background: #fff;
                    }
                }
                @media (min-width: 993px){
                    &#sumarySP{
                        display: none;
                    }
                    &:hover{
                        .dot_black{
                            margin-right: -24px;
                        }
                        .dot_white{
                            background: #fff;
                        }
                    }

                }
                @media (max-width: 992px){
                    &#sumaryPC{
                        display: none;
                    }
                }
            }
            @media (max-width: 992px){
                right: 25px;
                a{
                    h2{
                        display: none;
                    }
                }
            }
        }
    }

</style>
